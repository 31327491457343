import React, { useState }  from 'react';
import { Link, navigate, graphql, useStaticQuery } from 'gatsby';
import LogoIconNew from '../../images/luidsprekertje-icon.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button';

function HeaderFr() {
  const [isExpanded, toggleExpansion] = useState(false);
    const { site } = useStaticQuery(graphql`
    query SiteTitleQueryFr {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="sticky top-0 bg-white shadow">
    <div className="container mx-auto flex flex-wrap items-center justify-between max-w-7xl mx-auto md:pt-8 md:pb-8 md:pr-5 md:pl-5 text-xl">
      
        <Link to="/index-fr">
          <h1 className="flex items-center no-underline">
            <span className="text-xl font-bold tracking-tight text-black font-playfair">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <button
          className="items-center block px-3 py-2 text-black border border-black rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-stretch w-full md:w-auto`}
        >
        
      	   {[
            {
              route: `#kracht`,
              title: `A Propos`,
            },
            {
              route: `#aanpak`,
              title: `Approche`,
            },
            {
              route: `#gamma`,
              title: `Offres`,
            },
            {
              route: `#inspiratie`,
              title: `Inspiration`,
            },
          ].map((link) => (
	        
            <AnchorLink
              className="block mt-4 font-playfair no-underline md:inline-block md:mt-0 md:ml-6 text-black hover:underline hover:text-gray-800"
              href={link.route}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
            </AnchorLink>
            
          ))
           
          }
          <Link to="/contact-fr" className="block mt-4 font-playfair no-underline md:inline-block md:mt-0 md:ml-6 text-black hover:underline hover:text-gray-800">
		  	Contact
		  </Link>
		   <Link to="/" className="block p-2 bg-black rounded-full mt-4 font-playfair font-bold no-underline md:inline-block md:mt-0 md:ml-6 text-white hover:underline hover:text-white">
		  	NL
		  </Link>
		  
        </nav>
      </div>
    </header>
  );
}

export default HeaderFr;