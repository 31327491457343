import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import LayoutFr from '../components/layout/LayoutFr';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import HornImg from "../images/luidsprekerhartjes.png"
import WinkelImg from "../images/winkeltje.png"
import VrachtawagentjeImg from "../images/vrachtwagentje.png"
import WinkelmandjeImg from "../images/winkelmandje.png"
import SpaarvarkenImg from "../images/spaarvarkentje.png"
import ContactImg from "../images/envelopje.png"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "gatsby";

import aImg from "../images/deceulenerpics/Foto_1.jpg"
import Imgb from "../images/deceulenerpics/Foto_2.jpg"
import iImg from "../images/deceulenerpics/Foto_3.jpg"
import lImg from "../images/deceulenerpics/Foto_3.jpg"
import mImg from "../images/deceulenerpics/Foto_5.jpg"
import Img5 from "../images/deceulenerpics/Foto_5.jpg"
import Img4 from "../images/deceulenerpics/Foto_4.jpg"

import Imgf from "../images/deceulenerpics/Foto_6.jpg"
import nImg from "../images/deceulenerpics/Foto_7.jpg"
import oImg from "../images/deceulenerpics/Foto_8.jpg"
import pImg from "../images/deceulenerpics/Foto_9.jpg"

//import aImg from "../images/selection/section_one.jpg"
import bImg from "../images/selection/inspiratie_01.jpg"
import cImg from "../images/selection/inspiratie_02.jpg"
import dImg from "../images/selection/inspiratie_03.jpg"
import eImg from "../images/selection/inspiratie_04.jpg"
import fImg from "../images/selection/inspiratie_05.jpg"
import hImg from "../images/selection/inspiratie_06.jpg"
//import iImg from "../images/selection/kwaliteit.jpg"
import jImg from "../images/dc/person_01.jpg"
import kImg from "../images/dc/slide-jab-anstoetz-group-living-world-belle-couture-m02-l.jpeg"
//import lImg from "../images/selection/kwaliteit.jpg"
//import mImg from "../images/selection/gamma_luxaflex.jpg"
//import nImg from "../images/selection/gamma_jab_gordijnen.jpg"
//import oImg from "../images/selection/gamma_jab_tapijt.jpg"
//import pImg from "../images/selection/aanpak_01.jpg"
import qImg from "../images/selection/aanpak_02.jpg"
import rImg from "../images/selection/aanpak_03.jpg"

const Index = () => (
  <LayoutFr >
    <section className="text-gray-600 body-font bg-gray-300">
		<div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
	    	<div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">		
				<h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Deceulener Interieur
				</h1>
				<p className="mb-8 leading-relaxed">Accueil chaleureux et sympathique, avis et conseils professionnels dans la recherche de la perfection et de l’originalité pour créer un intérieur selon vos goûts les plus personnels et vos désirs les plus intimes !</p>
				<p className="mb-8 leading-relaxed">Tout est mis en œuvre pour répondre à vos demandes et vos attentes les plus exigeantes.</p>
			</div>	
			
		    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
		    	    
						<img className="object-cover object-center rounded" src={aImg} alt="Listen" />
					
		    </div>
	    </div>
	</section>	
	
	<section id="kracht" className="text-gray-600 body-font bg-gray-300">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Notre force</h1>
      <center><div className="h-1 w-20 bg-black rounded"></div></center>
      <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto pt-10">Depuis 1946 la maison Deceulener intérieur s’est fait un devoir de conserver et de perpétuer au cours des années les valeurs de qualité et de professionnalisme qui lui sont propres:</p>
    </div>
    <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Accueil Chaleureux</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Conseil personnalisé</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Recherche de la perfection</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Un oeil pour le détail et la beauté</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Excellent service après-vente</span>
        </div>
      </div>
      <div className="p-2 sm:w-1/2 w-full">
        <div className="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-black w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span className="title-font font-medium">Qualité et le savoir-faire </span>
        </div>
      </div>
    </div>
  </div>
</section>
	
	<section id="inspiratie" className="text-gray-600 body-font">
		<div className="container px-5 py-24 mx-auto">
		    <div className="flex flex-wrap w-full mb-20">
				<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
					<h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Inspiration</h1>
					<div className="h-1 w-20 bg-black rounded"></div>
				</div>
				<p className="lg:w-1/2 w-full leading-relaxed text-gray-500">C’est pour nous un plaisir sans cesse renouvelé que d’aller à la rencontre des désirs et des rêves de nos clients. Les projets que nous réalisons nous tiennent à cœur car nous désirons nous investir pleinement avec émotion et motivation dans la création et la finalisation des travaux que nous apportent.</p>
			</div>
    
    
    
    <div className="flex flex-wrap md:-m-2 -m-1">
      <div className="flex flex-wrap w-1/2">
        <div className="md:p-2 p-1 w-1/2">
        
        		<a href="/contact">
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={Img5}></img>
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
          
          	
          
        </div>
        <div className="md:p-2 p-1 w-1/2">
        
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={Img4}></img>
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
        		
        	
				
			
        </div>
        <div className="md:p-2 p-1 w-full">
        
        	
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={hImg}></img>
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
				
			
        </div>
      </div>
      <div className="flex flex-wrap w-1/2">
        <div className="md:p-2 p-1 w-full">
        		
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={iImg}></img>
			
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
			
        </div>
        <div className=" group md:p-2 p-1 w-1/2">
        		
        		<a href="/contact">
        		
				<img alt="gallery" className="w-full object-cover h-full object-center block rounded shadow-xl hover:shadow-2xl" src={kImg}></img>
			
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
				
		        </div>
        
        <div className="md:p-2 p-1 w-1/2">
        		
        		<a href="/contact">
        		
						<div className="rounded w-full object-cover h-full object-center block" style={{backgroundImage: 'url('+Imgb+')', backgroundSize: "cover"}}> 
							<div className="group">
							</div>
						</div> 
				
				<figcaption className="absolute text-lg -mt-16 text-white px-4">
				<div>
		        <h1 className="opacity-100 group-hover:opacity-0 duration-300"></h1>
				</div>
				<div>
		        <h1></h1>
				</div>
				</figcaption>
				</a>
				
				
				  
        </div>
        
        

        
      </div>
    </div>
  </div>
</section>
	
	
	
	
	
	
	
	


	<section className="text-gray-600 body-font bg-gray-300">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Votre intérieur, votre rêve … notre métier.</h1>
      <center><div className="h-1 w-20 bg-black rounded"></div></center>
    </div>
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <p className="mt-8 text-xl font-light leading-relaxed">
            Notre collection de vitrages et tentures avec toutes les accessoires sont continuellement renouvelées. 
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
		  Chez nous, vous pouvez trouver le couvre-fenêtres fonctionnellement et décoratives comme:          
		  <li>stores bateaux-plissés et rouleaux</li>
          <li>stores duette et stores Vénitiens Silhouette</li>
          <li>stores vénitiens et californiens et stores en bois</li>
			</p>
			 <p className="mt-8 text-xl font-light leading-relaxed">
			 Nous avons également le plaisir de vous proposer un bel assortiment de tapis contemporains ainsi que de tapis plein , tout étant fait sur mesure.
			 </p>
			<p className="mt-8 text-xl font-light leading-relaxed">
            Pour les plus exigeants des adaptations électriques sont possible. Grâce à la PowerView Automation, vous pouvez décider quand votre couvre-fenêtres s’ouvre ou se referme tout seul et automatiquement. Et ça tout moment pendant la journée ou le nuit. 
			</p>
        </div>
      }
      secondarySlot={<img src={lImg} alt="Listen" />}
    />
        	
        </div>	
    </section>
    
    
   	<section id="gamma" className="text-gray-600 body-font">
		<div className="container px-5 py-24 mx-auto">
		    <div className="flex flex-wrap w-full mb-20">
				<div className="lg:w-1/2 w-full mb-6 lg:mb-0">
					<h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Nos offres</h1>
					<div className="h-1 w-20 bg-black rounded"></div>
				</div>
				<p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Nous travaillons seulement avec les matériaux des meilleurs fournisseurs. En outre, nous pouvons compter sur une longue collaboration avec des marques renommées et nous assurons une installation professionnelle. Comme ça, vous pouvez rapidement profiter !</p>
			</div>
        
        
        
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">LUXAFLEX - the art of window styling</p>
              <p className="mt-4 pb-4">
			  Chez Luxaflex, magnifiques produits commencent avec une conception puissante. Depuis 70 ans, Luxaflex représente une décoration exclusive et sur mesure pour toute la maison. </p>
              {<img className="rounded" src={mImg} alt="Listen" />}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">JAB Anstoetz – Rideaux et tentures</p>
              <p className="mt-4 pb-4">
			  Le plus grand éditeur du monde de matières pour housses de fenêtre et de siège, tant un style classique, moderne ou design.
			  </p>
              {<img className="rounded" src={nImg} alt="Listen" />}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">JAB ANSTOETZ – tapis et tapis plein</p>
              <p className="mt-4 pb-4">
			  Sur mesure, possible avec ou sans bordure. Des qualités en laine, synthétique et même les fibres écologique recyclé. 			  	</p>
              {<img className="rounded" src={oImg} alt="Listen" />}
            </Card>
          </div>
        </div>
      </div>
    </section>
    
    
    	<section id ="aanpak" className="text-gray-600 body-font bg-gray-300">
  <div className="container px-5 py-24 mx-auto">
    <div className="text-center mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Notre approche</h1>
      <center><div className="h-1 w-20 bg-black rounded"></div></center>
      
    </div>
    
    
    
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
		  Stade 1: nous écoutons votre besoins avec une attention à vos goûts personnels.           </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
		  Grâce à notre expérience et une large gamme de produits et de styles, nous arrivons à une compréhension commune sur les besoins et faveurs.           
		  </p>
        </div>
      }
      secondarySlot={<img src={pImg} alt="Listen" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Stade 2: Choisir sans souci mais avec soin
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
		  	En regardant et en sentant les matériaux et grâce à notre avis professionel, nous arrivons rapidemment à quelques options qui conviennent votre style personnel. 		  	</p>
        </div>
      }
      secondarySlot={<img src={qImg} alt="Listen" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
		  	Stade 3: livraison et placement          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
		  Nous assurons une livraison et installation des produits sans problèmes. Tout ce que vous avez faire est de profiter.          </p>
        </div>
      }
      secondarySlot={<img src={rImg} alt="Listen" />}
    />
    
      </div>
    </section>
    
    

    
    
    
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">		
			<div>
				<h3 className="text-5xl font-semibold">
				Voilà est-ce que le style vous convient?
				</h3>
				<p className="mt-8">
					<Link to="/contact" className="px-4">
						<button size="xl" className="inline-flex text-white bg-black border-0 py-2 px-6 focus:outline-none hover:bg-gray-500 rounded text-lg">
						Contactez-nous.
						</button>
					</Link>
					
				</p>
				
		
		</div>	
    </section>
    
        
    
  </LayoutFr>
);

export default Index;
